$(document).ready(function() {
	$(document).on("click", '.burger', function(){
	    $(this).toggleClass('open').next(".navigation").toggleClass('open');
	});
	$(".fancybox").fancybox();

	$('input').blur(function () {
		clearFormStatus($(this).closest('form')[0]);

		if ($(this).val().length) {
            $(this).addClass('not-empty');
		} else {
            $(this).removeClass('not-empty');
		}
    });

	$('form').on('submit', function (e) {
		e.preventDefault();

        var url = $(e.target).attr('action');
        var formData = $(e.target).serializeArray();

        makePending(e.target);
        $.post(url, formData)
			.done(function (data) {
                clearFormStatus(e.target);
			    if (data["MusePHPFormResponse"]["success"]) {
                    makeSuccess(e.target);
                    e.target.reset();
                }

			})
			.fail(function (data) {
				console.log(data);
                clearFormStatus(e.target);
                makeError(e.target);
            });
    });

	function makeError(form) {
        if (form) {
            Array.from(form.querySelectorAll('.formStatus.error')).forEach(function (item) {
                item.style.display = 'block';
            });
		}
    }

    function makePending(form) {
        if (form) {
            Array.from(form.querySelectorAll('.formStatus.pending')).forEach(function (item) {
                item.style.display = 'block';
            });
		}
    }

    function makeSuccess(form) {
        if (form) {
            Array.from(form.querySelectorAll('.formStatus.success')).forEach(function (item) {
                item.style.display = 'block';
            });
		}
    }

    function clearFormStatus(form) {
		if (form) {
            Array.from(form.querySelectorAll('.formStatus')).forEach(function (item) {
				item.style.display = 'none';
            });
		}
    }
});